<template>
  <div class="card">
      <div class="card-header color-dark fw-500">
          Document
      </div>
      <div class="card-body p-0">
          <div class="table4  p-25 bg-white mb-30">
              <div class="table-responsive">
                  <table class="table mb-0">
                      <thead>
                          <tr class="userDatatable-header">
                            <th>
                                  <span class="userDatatable-title">ID</span>
                              </th>
                              <th>
                                  <span class="userDatatable-title">Document Name</span>
                              </th>
                              <th>
                                  <span class="userDatatable-title">Document Prefix</span>
                              </th>
                              <th>
                                  <span class="userDatatable-title">Document Type</span>
                              </th>
                              <th>
                                  <span class="userDatatable-title">Status</span>
                              </th>
                              <th>
                                  <span class="userDatatable-title">Create By</span>
                              </th>
                              <th>
                                  <p class="userDatatable-title m-0 text-center">Action</p>
                              </th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr v-for="(e,index) in listData" :key="index" >
                              <td>
                                  <div class="userDatatable-content ">
                                      {{e.id}}
                                  </div>
                              </td>
                              <td>
                                  <div class="userDatatable-content">
                                      {{e.doc_name}}
                                  </div>
                              </td>
                              <td>
                                  <div class="userDatatable-content">
                                      {{e.prefix}}
                                  </div>
                              </td>
                              <td>
                                  <div class="userDatatable-content">
                                      {{e.doc_type}}
                                  </div>
                              </td>
                              <td>
                                  <div class="userDatatable-content">
                                    <span :class="`badge badge-round ${e.status=='active'?'badge-success':'badge-danger'} badge-lg`">
                                      {{e.status}}
                                    </span>
                                  </div>
                              </td>
                              <td>
                                  <div class="userDatatable-content">
                                      {{e.created_by}}
                                  </div>
                              </td>
                              <td>
                                  <div class="userDatatable-content">
                                      <ul class="orderDatatable_actions mb-0 d-flex justify-content-center">
                                          <!-- <li>
                                              <a href="#" class="view">
                                                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path><circle cx="12" cy="12" r="3"></circle></svg></a>
                                          </li> -->
                                          <li>
                                              <a href="#" class="edit">
                                                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path></svg></a>
                                          </li>
                                          <li>
                                              <a @click="swalDeleteDoc(e)" class="remove">
                                                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg></a>
                                          </li>
                                      </ul>
                                  </div>
                              </td>
                          </tr>
                      </tbody>
                  </table>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
  name: "TableListDocument",
  data() {
    return {
      listData:[]
    };
  },
  mounted() {
    this.listTable()
  },
  methods: {
    async listTable() {
      try {
        let data = {}
        let getAPI = await this.$API.call_API("get","workflow/DocumentTemplates",data,"auth");
        this.listData = getAPI.data.data
      } catch (error) {
        this.$MAIN.showErrorAlert(this, error);
      }
    },
    async swalDeleteDoc(e){
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          this.DeleteDoc(e)
          
        }
      })
    },
    async DeleteDoc(e){
      let data = {
        docId: e.id
      }
      let getAPI = await this.$API.call_API("delete","workflow/DocumentTemplates",data,"auth");
      this.listTable()
      Swal.fire(
        'Deleted!',
        'Your file has been deleted.',
        'success'
      )
    }
  },
};
</script>

<style scoped>

</style>