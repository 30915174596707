<template>
  <section>
    <Button1 class="mr-3" tital="Add Document" data-toggle="modal" data-target="#ModalAddDocument" @click="reloadmodal = Date.now()" />
    <!-- Modal -->
    <div class="modal fade" id="ModalAddDocument" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true" >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-body">
            <div class="row m-0 p-0">
              <!-- -------------- head ------------------ -->
              <div class="col-12">
                <p class="mb-5 titalmodal">Add Document</p>
                <p class="mb-5 subtitalmodal">เพิ่มเอกสารใหม่ของคุณ</p>
              </div>
              <!-- ------------------------ body ---------------------- -->
              <div class="col-12">
                <form>
                  <div class="row m-0 p-0">

                    <div class="col-12">
                      <div class="form-group">
                        <label>Document Name</label>
                        <input type="text" class="form-control" placeholder="Document Name" v-model="addDocumentData.docName" />
                      </div>
                    </div>
    
                    <div class="col-12">
                      <div class="form-group">
                        <label>Document Prefix</label>
                        <input type="text" class="form-control" placeholder="Document Prefix" v-model="addDocumentData.docPrefix" />
                      </div>
                    </div>

                    <div class="col-12">
                      <div class="form-group">
                        <label>Document Type</label>
                        <input type="text" class="form-control" placeholder="Document Type" v-model="addDocumentData.docType" />
                      </div>
                    </div>
                
                  </div>
                </form>
              </div>

              <!-- ------- close-------------------- -->
              <div class="col-12 m-0 p-0 d-flex justify-content-end">
                <button type="button" class="btn close mx-2 px-5" data-dismiss="modal" > Close </button>
                <button  type="button" class="btn comfirm mx-2 px-5" data-dismiss="modal" @click="Confirm()" >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Button1 from "@/components/Common/Button1.vue";
export default {
  name: "ModalAddDocument",
  components: {
    Button1
  },
  data() {
    return {
      addDocumentData:{
        docName:'',
        docType:'',
        docPrefix:''
      }
    };
  },
  mounted() {
  },
  methods: {
    async Confirm() {
      try {
        let data = this.addDocumentData;
        let getAPI = await this.$API.call_API("post","workflow/DocumentTemplates",data,"auth");
        // alert(JSON.stringify(data));
        console.log(data);
        this.addDocumentData={
          docName:'',
          docType:'',
          docPrefix:''
        }
        this.$emit('addDocumentSuccess')
      } catch (error) {
        this.$MAIN.showErrorAlert(this, error);
      }
    },
  },
};
</script>

<style scoped>
.titalmodal {
  color: #0f172a;
  font-weight: 600;
  font-size: 2.25em;
}
.subtitalmodal {
  color: #0f172a;
  font-weight: 600;
  font-size: 1.125em;
}
.close {
  color: #475569;
  font-weight: 600;
  font-size: 0.75em;
  background: #ffffff;
  border: 1px solid #cbd5e1;
  border-radius: 5px;
}
.comfirm {
  color: #f8fafc;
  font-weight: 600;
  font-size: 0.75em;
  background: #20c997;
  border: 1px solid #20c997;
  border-radius: 5px;
}
</style>