<template>
  <div
    :class="`${
      $store.getters['display/getAside'] ? 'contents expanded' : 'contents'
    }`"
  >
    <div class="card m-0 p-0">
      <div class="row m-0 p-0 w-100">

        <div class="col-12 col-md-12 col-lg-4 m-0 p-0">
          <LeftHeader
            tital="Document "
            subtital="Template"
            line="1.2"
          />
        </div>

        <div class="col-12 col-md-12 col-lg-8 m-0 p-4">
    
        </div>
    
        <div class="col-12 m-0 p-0">
          <div class="row m-0 p-0">
            <div class="col-12 m-0 p-0 d-flex justify-content-end">
              <ModalAddDocument :key="reloadmodal" @addDocumentSuccess="reloadTable= Date.now()" />
            </div>
          </div>
        </div>
        <div class="col-12 mb-3">
          <TableListDocument :key="reloadTable" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LeftHeader from "@/components/Common/LeftHeader.vue";
import Button1 from "@/components/Common/Button1.vue";
import ModalAddDocument from "@/components/DocumentTemplate/ModalAddDocument.vue";
import TableListDocument from "@/components/DocumentTemplate/TableListDocument.vue";
export default {
  name: "DocumentTemplate",
  components: {
    LeftHeader,
    Button1,
    ModalAddDocument,
    TableListDocument
  },
  data() {
    return {
      reloadmodal: "",
      reloadTable:""
    };
  },
  methods: {
    callmodal() {
      this.reloadmodal = Date.now();
    },
    goto() {
      this.$router.push({ name: "WorkFlowPage" });
    },
  },
};
</script>

<style scoped>
.bgcard {
  background: #ffffff;
  box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.25);
  border-radius: 27px;
}
.text {
  font-size: 0.75em;
  color: #000000;
  font-weight: 400;
}
.text1 {
  font-size: 0.875em;
  color: #000000;
  font-weight: 700;
}
.text2 {
  font-size: 0.75em;
  color: #000000;
  font-weight: 700;
}
.border1 {
  border-bottom: 1px solid #c4c4c4;
}
.border2 {
  border-bottom: 1px solid #0f172a;
}
</style>